<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.UserId"
        placeholder="UserId"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.Email"
        placeholder="Email"
        style="width: 200px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.SubAccUserId"
        placeholder="SubAccUserId"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-input
        v-model="listQuery.OrderId"
        placeholder="OrderId"
        style="width: 180px;"
        class="filter-item"
        size="small"
        clearable
      />
      <el-select
        v-model="listQuery.PotType"
        clearable
        placeholder="Reward Type"
        size="small"
        class="filter-item"
      >
        <el-option
          v-for="item in potTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select
        v-model="listQuery.TokenCode"
        clearable
        placeholder="Token"
        size="small"
        class="filter-item"
      >
        <el-option label="USDT" value="usdt"></el-option>
        <el-option label="POT" value="pot"></el-option>
      </el-select>
      <el-date-picker
          class="filter-item"
          v-model="dateArr"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          align="right"
          value-format="yyyy-MM-dd HH:mm:ss"
          unlink-panels
          range-separator="-"
          start-placeholder="start"
          end-placeholder="end"
          size="small"
      ></el-date-picker>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="handleFilter"
        >Search</el-button
      >
      <span class="filter-item">
        <download-excel
          :fields="json_fields"
          :fetch="handleExport"
          type="xls"
          name="RewardAllocation.xls"
        >
        <el-button
          type="primary"
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleFilter"
        >Export</el-button>
        </download-excel>
      </span>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="Created on" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.CreatedOnUtc | dateStr }}
        </template>
      </el-table-column>
      <el-table-column label="Email" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.UserEmail }}
        </template>
      </el-table-column>
      <el-table-column label="Mining Power" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.MiningPower }}
        </template>
      </el-table-column>
      <el-table-column label="Token Issued" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.TokenIssue }}
        </template>
      </el-table-column>
      <el-table-column label="Token Code" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.TokenCode }}
        </template>
      </el-table-column>
      <el-table-column label="Reward Type" min-width="110" align="center">
        <template slot-scope="scope">
          {{ getPotTypeName(scope.row.IssueType) }}
        </template>
      </el-table-column>
      <el-table-column label="Fees" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.Reward }}
        </template>
      </el-table-column>
      <el-table-column label="FCR" min-width="110" align="center">
        <template slot-scope="scope">
          <div>FullReward: {{ scope.row.FullReward }}</div>
          <div>Ratio: {{ scope.row.CrossRatio }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Full POT Issue" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.FullPOTIssue }}
        </template>
      </el-table-column>
      <el-table-column label="TokenPrice" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.TokenPrice }}
        </template>
      </el-table-column>
      <el-table-column label="OverBacking" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.OverBacking }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: "success",
        draft: "gray",
        deleted: "danger"
      };
      return statusMap[status];
    }
  },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        UserId: '',
        SubAccUserId: '',
        OrderId: '',
        Email: '',
        DateFrom: "",
        DateTo: "",
        PotType: '',
        TokenCode: '',
      },
      dateArr: null,
      potTypes: [
        {label: "TRADER", value: 10},
        {label: "REFERRAL", value: 20},
        {label: "CHALLENGE", value: 30},
        {label: "BD", value: 40},
        {label: "AMBASSADOR", value: 50},
        {label: "TEAM", value: 60},
        {label: "NFT", value: 70},
        {label: "TREASURY1", value: 80},
        {label: "TREASURY2", value: 90},
      ],
      exportLoading: false,
      json_fields: {
        CreatedOnUtc: 'CreatedOnUtc',
        UserEmail: 'UserEmail',
        MiningPower: 'MiningPower',
        TokenIssue: 'TokenIssue',
        TokenCode: 'TokenCode',
        IssueType: {
          field: "IssueType",
          callback: (value) => {
            return this.getPotTypeName(value);
          },
        },
        Reward: 'Reward',
        FullReward: 'FullReward',
        CrossRatio: 'CrossRatio',
        FullPOTIssue: 'FullPOTIssue',
        TokenPrice: 'TokenPrice',
        OverBacking: 'OverBacking',
      },
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.DateFrom = newVal[0];
        this.listQuery.DateTo = newVal[1];
      } else {
        this.listQuery.DateFrom = "";
        this.listQuery.DateTo = "";
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/admin/report/rewardAllocation",
        method: "get",
        params: this.listQuery
      }).then(response => {
        console.log(response.data);
        this.list = response.data.Data;
        this.total = response.data.Total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getPotTypeName(val) {
      if (val == 10) return 'TRADER';
      if (val == 20) return 'REFERRAL';
      if (val == 30) return 'CHALLENGE';
      if (val == 40) return 'BD';
      if (val == 50) return 'AMBASSADOR';
      if (val == 60) return 'TEAM';
      if (val == 70) return 'NFT';
      if (val == 80) return 'TREASURY1';
      if (val == 90) return 'TREASURY2';
      if (val == 100) return 'RECLAIM_POT';
      if (val == 110) return 'RECLAIM_USDT';
      if (val == 120) return 'OVERBACKING';
    },
    handleExport() {
      this.exportLoading = true;
      var query = JSON.parse(JSON.stringify(this.listQuery));
      query.limit = 99999999999999;
      return request({
        url: "/api/admin/report/rewardAllocation",
        method: "get",
        params: query,
      })
      .then((result) => {
        var json_data = result.data.Data;
        return json_data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.exportLoading = false;
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
